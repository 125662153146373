/* roboto-regular - latin */
@font-face {
    font-display: swap; 
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/roboto-v30-latin-regular.woff2') format('woff2'), 
         url('/fonts/roboto-v30-latin-regular.ttf') format('truetype'); 
  }
  
  /* roboto-700 - latin */
  @font-face {
    font-display: swap; 
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/roboto-v30-latin-700.woff2') format('woff2'), 
         url('/fonts/roboto-v30-latin-700.ttf') format('truetype'); 
  }
  