@import "../../styles/mixins";

.main__data {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1190px;
    justify-content: center;
    align-content: flex-start;
    margin: 0 auto 55px auto;
}

.data-header {
    width: 100%;
    height: max-content;
    margin: 63px 0 90px 37px;

    @include tablet {
        margin: 50px 0 50px 35px;
    }

    &__title {
        font-size: rem(48);
        margin-bottom: rem(25);

        &--firstname {
            color: $primary;
        }
    }

    &__text {
        font-size: rem(18);
    }
}


/******** DATA KEYDATA *********/
.data-keydata {
    width: 23%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    transition: all 0.2s;
    padding: 15px;

    @include tablet {
        gap: 23px;
    }

    &__card {
        width: 100%;
        background-color: $grey-light;
        border-radius: 5px;
        height: 124px;
        padding-left: 30px;
        transition: all 0.2s;
        display: flex;
        gap: 24px;
        align-items: center;

        @include tablet {
            height: 89px;
            padding-left: 16px;
            gap: 15px;
        }

        
    }
}
