@import "mixins";
@import "reset";
@import "fonts";

html {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;

    @include tablet {
        font-size: 14px;
	}
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eee;
    min-height: 100vh;
}



.content {
    width: 100%;
    max-width: 1440px;
    min-width: 1024px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    position: relative;
    flex-grow: 1;
}







.data-charts {
    width: 73%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px 0;
    height: max-content;
    padding: 15px;
    transition: all 0.3s;
    

    @include tablet {
        gap: 23px 0;
    }

    &__card {
        width: 31%;
        background-color: $grey-light;
        border-radius: 5px;
        height: 260px;
        transition: all 0.2s;

        @include tablet {
            height: 183px;
        }

        .error-message {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10%;
            font-size: rem(16);
            line-height: 130%;
        }
    }

    &__activity {
        width: 100%;
        height: 295px;
        transition: all 0.2s;
        
        @include tablet {
            height: 222px;
        }
    }
}