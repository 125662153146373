@import "../../styles/mixins";

.data-charts__activity {
    padding: 30px 10px 20px 30px;
    font-size: rem(14);
    position: relative;

    @include tablet {
        padding: 20px 0px 20px 20px;
    }

    h2 {
        font-size: rem(16);
        position: absolute;
        top: 30px;
        left: 30px;
        font-weight: 400;
        color: #20253A;

        @include tablet {
            top: 20px;
            left: 20px;
        }
    }
}