@import "../../styles/mixins";

.data-charts__sessions {
    font-size: rem(14);
    position: relative;
    background-color: #FF0000;
    //padding: 60px 15px 10px 15px;

    h2 {
        font-size: rem(16);
        position: absolute;
        top: 30px;
        left: 30px;
        font-weight: 400;
        color: rgba($color: #FFF, $alpha: .6);
        width: 75%;
        line-height: 130%;

        @include tablet {
            top: 20px;
            left: 20px;
        }
    }

    .error-message {
        color: #fff;
    }

}